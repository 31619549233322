div#singleTileControl {
  position: absolute;
  top: 45px;
  right: 0;
  width:130px;
  z-index: 10;
  /* height: 55px; */
  background-color: transparent;
  /* border: 1px solid red; */
}

div#singleTileControlTitle {
  text-align: center;
  font-size: 10px;
  font-weight: 600;
  color: #090909;
  text-transform: uppercase;
  /* margin-left: 5px; */
}

div#singleTileControl div.qs_main {
  z-index: 6;
  pointer-events: none;
}

div#single-tile-selector-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 50px;
}

div#singleTileControl div.qs_main div.qs_content div#single-tile-selector-container.qs_container div#pop-up.pop-up {
  display: none;
  z-index: 10;
  pointer-events: auto;
}

div#singleTileControl div.qs_main div.qs_content div#single-tile-selector-container.qs_container div#pop-up.pop-up.shown {
  display: block;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
  overflow-x: hidden;
}

div#singleTileControl div.qs_main div.qs_content div#single-tile-selector-container.qs_container div#pop-up.pop-up.shown div#pop-up-wapper.pop-up-wapper {
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 100%;
}

.close-single-tile-pop-up {
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  margin: 0 10px;
}
/* end nav */

.single-tile-img-div {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 0px;
}

.single-tile-img-div.selected {
  background-color: #fff;
  border-radius: 6px 0 0 6px;
}

.single-tile-img-div img {
  width: 65px;
  cursor: pointer;
}

div#singleTileControl div.qs_main div.qs_content div.qs_container div#single-tile-selector-wrapper.menu-navigation {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 50px;
  background-color: #fff;
}

/* nav menu */
div#singleTileControl div.qs_main {
  width: 100%;
}

div#singleTileControl div.qs_main div.qs_content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

div#single-tile-selector-wrapper{
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-evenly;
  background-color: #fff;
  border-radius: 6px;
  height: 100%;
  width: 60px;
}

div#single-tile-selector-img-wrapper.single-tile-selector-img img.single-tile-img {
  height: 57px;
  cursor: pointer;
}

div#single-tile-selector-img-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: auto;
}


/* Color selector */
div#singleTileControl div.qs_main div.qs_content div.qs_container {
  display: flex;
  flex-direction: column;
  width: 62px;
  min-height: 60px;
  overflow: hidden;
  pointer-events: auto;
}

div#singleTileControl div.qs_main div.qs_content div.qs_container div.single-tile-color-selector {
  display: flex;
  height: 60px;
  width: 60px;
  border: 1px solid #DFDFDF;
  border-radius: 6px 0 0 6px;
  background-color: #fff;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  z-index: 5;
  box-sizing: content-box;
}

.single-tile-color-selector-wrapper {
  height:52px;
  width: 52px;
  border-radius: 6px;
  overflow: hidden;
  cursor: pointer;
}

.single-tile-texture,
.single-tile-color {
  height: 100%;
  width: 100%;
  border: 1px solid #DFDFDF;
  border-radius: 6px;
}

.color-side-bar {
  width: 60px;
  background-color: #fff;
  border: 1px solid #DFDFDF;
  border-radius: 0 0 6px 6px;
  margin-left: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 7px;
  margin-top: -10px;
  transition: margin 0.5s;
  animation: all 1.5s;
  transition: padding 0.2s;
}

.color-side-bar.shown {
  display: flex;
}

.side-bar-color-tiles {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  overflow-y: auto;
  transition: max-height 0.4s;
  overflow-x: hidden;
}

.color-side-bar.shown .side-bar-color-tiles{
  max-height: 60vh;
  padding: 1px 0;
}

.color-side-bar:not(.shown) .side-bar-color-tiles {
  max-height: 0;
}

.single-tile-color-div {
  min-height: 50px;
  width: 50px;
  border-radius: 6px;
  border: 1px solid #DFDFDF;
  margin: 15px 0 5px 0;
  cursor: pointer;
}

.single-tile-img-div.hide {
  display: none;
}

img.single-tile-detail {
  width: 100%;
  align-self: center;
  margin-bottom: 20px;
}

div.single-tile-color-div-text {
  display: flex;
  flex-direction: column;
  font-size: 10px;
  /* line-height: 9px; */
  text-transform: lowercase;
  text-align: center;
  position: relative;
  width: 100%;
  /* height: 100%; */
  align-items: center;
  padding: 0px 3px;
  font-family: Calibri;
}

div#singleTileControl div.qs_main div.single-tile-control-color-name-title {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;
  width: 50px;
  height: 50px;
  top: 20px;
  right: 6px;
  text-align: center;
  text-transform: lowercase;
  pointer-events: none;
  z-index: 6;

  font-size: 9px;
}
