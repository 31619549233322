.edgeStagePanelContainer {
  display: flex;
  position: fixed;
  z-index: 5;
}

.edgePopUpContainer {
  display: none;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 1;
  align-items: center;
  justify-content: center;
}

.edgePopUp {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 400px;
  max-width: 100%;
  border-radius: 6px;
  padding: 40px 8px;
  background-color: #ffffff;
  align-items: center;
}

.edgePopUpClose {
  display: flex;
  position: absolute;
  right: 0;
  top: 0;
  margin: 8px 8px 0 0;
  justify-content: center;
  height: 25px;
  width: 25px;
  padding: 2px;
  border-radius: 6px;
  background-color: white;
  -webkit-box-shadow: 0px 2px 5px -1px rgba(199,199,199,1);
  -moz-box-shadow: 0px 2px 5px -1px rgba(199,199,199,1);
  box-shadow: 0px 2px 5px -1px rgba(199,199,199,1);
  cursor: pointer;
}

.edgepopUpText {
  text-align: center;
  user-select: none;
}

.edgePopUpOk {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  padding: 2px;
  margin-top: 20px;
  border-radius: 6px;
  background-color: white;
  -webkit-box-shadow: 0px 2px 5px -1px rgba(199,199,199,1);
  -moz-box-shadow: 0px 2px 5px -1px rgba(199,199,199,1);
  box-shadow: 0px 2px 5px -1px rgba(199,199,199,1);
  cursor: pointer;
}

.edgeStagePanelRadioButtonWrapperContainer {
  position: fixed;
  left: 50%;
  top: calc(31px + 2.5%);
}

.edgeStagePanelRadioButtonWrapper {
  display: flex;
  position: relative;
  border-radius: 6px;
  overflow: hidden;
  height: 40px;
  width: 300px;
  max-height: 7%;
  max-width: 70vw;
  left: -50%;
  background-color: #d8d8d8;
  overflow: visible;
  user-select: none;
}

.edgeStateInput {
  position: absolute;
  visibility: hidden;
  display: none;
}

.edgeStateLabel {
  display: flex;
  position: relative;
  box-sizing: border-box;
  flex: 1;
  color: black;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  z-index: 1;
}

.edgeRadioBtnSelector {
  display: flex;
  position: absolute;
  width: 33.33%;
  top: -1px;
  height: calc(100% + 2px);
  border-radius: 6px;
  border: 1px solid #fff;
  transition: transform .2s;
  -webkit-box-shadow: 0px 2px 7px -3px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 2px 7px -3px rgba(0,0,0,0.75);
  box-shadow: 0px 2px 7px -3px rgba(0,0,0,0.75);background: rgba(252,252,252,1);
  background: -moz-linear-gradient(top, rgba(252,252,252,1) 0%, rgba(235,235,235,1) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(252,252,252,1)), color-stop(100%, rgba(235,235,235,1)));
  background: -webkit-linear-gradient(top, rgba(252,252,252,1) 0%, rgba(235,235,235,1) 100%);
  background: -o-linear-gradient(top, rgba(252,252,252,1) 0%, rgba(235,235,235,1) 100%);
  background: -ms-linear-gradient(top, rgba(252,252,252,1) 0%, rgba(235,235,235,1) 100%);
  background: linear-gradient(to bottom, rgba(252,252,252,1) 0%, rgba(235,235,235,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fcfcfc', endColorstr='#ebebeb', GradientType=0 );
}

#edgeStateRadioBtn1:checked ~ #edgeRadioBtnSelector {
  transform: translateX(100%);
}

#edgeStateRadioBtn2:checked ~ #edgeRadioBtnSelector {
  transform: translateX(200%);
}

.edgeAllColorPicker {
  display: none;
  flex-grow: 0;
  position: fixed;
  left: 0;
  bottom: calc(100px + 4.5%);
  width: 62px;
  height: 62px;
}

.edgeSingleColorPicker {
  display: none;
  flex-direction: column;
  flex-grow: 0;
  position: fixed;
  right: 0;
  bottom: calc(100px + 4.5%);
  width: 62px;
  height: 62px;
}

.edgeColorPickerText {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
  top: -80%;
  line-height: 1;
  font-size: 14px;
  text-align: center;
  width: 62px;
}
