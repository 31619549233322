div#tileControl {
  position: absolute;
  top: 45px;
  width:130px;
  z-index: 10;
  /* height: 55px; */
  background-color: transparent;
  margin-left: 10px;
}

div#tileControlTitle {
  text-align: center;
  font-size: 10px;
  font-weight: 600;
  color: #090909;
  text-transform: uppercase;
}

div#tileControl div.qs_main {
  z-index: 6 !important;
}

div#tileControl div.qs_main div.qs_content div#tile-selector-container.qs_container div#pop-up.pop-up {
  display: none;
  z-index: 10;
  pointer-events: auto;
}

div#tileControl div.qs_main div.qs_content div#tile-selector-container.qs_container div#pop-up.pop-up.shown {
  display: block;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
  overflow: hidden;
}

#pop-up-wapper {
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 100%;
  max-width: 600px;
}

/* NAV */
#pop-up-nav {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 41px;
  padding: 8px 20px;
}

#pop-up-nav-left {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: flex-start;
  align-items: center;
  text-align: left;
}

.pop-up-nav-icon {
  width: 16px;
  height: 23px;
}

.pop-up-nav-text {
  font-weight: 600;
}

#pop-up-nav-right {
  display: flex;
  justify-content: center;
  height: 25px;
  width: 25px;
  padding: 2px;
  border-radius: 6px;
  background-color: white;
  -webkit-box-shadow: 0px 2px 5px -1px rgba(199,199,199,1);
  -moz-box-shadow: 0px 2px 5px -1px rgba(199,199,199,1);
  box-shadow: 0px 2px 5px -1px rgba(199,199,199,1);
  cursor: pointer;
}

.close-tile-pop-up {
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  margin: 0 10px;
}
/* end nav */

#pop-up-tiles {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  width: 80px;
  align-items: center;
  background-color: #e9e9e9;

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
#pop-up-tiles::-webkit-scrollbar {
  display: none;
}

.tile-img-div {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 0px;
}

.tile-img-div.selected {
  background-color: #fff;
  border-radius: 6px 0 0 6px;
}

.tile-img-div img {
  width: 65px;
  cursor: pointer;
}

div#tileControl div.qs_main div.qs_content div.qs_container div#tile-selector-wrapper.menu-navigation {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 50px;
  overflow: hidden;
  background-color: white;
}

/* nav menu */
div#tileControl div.qs_main {
  width: 100%;
}

div#tileControl div.qs_main div.qs_content {
  width: 100%;
  overflow: hidden;
}

div#tileControl div.qs_main div.qs_content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

div#tile-selector-wrapper{
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-evenly;
  background-color: #fff;
  border-radius: 6px;
  overflow: hidden;
  height: 60px;
  width: 60px;
}

div#tile-selector-img-wrapper.tile-selector-img img.tile-img {
  height: 57px;
  cursor: pointer;
}

div#tile-selector-img-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* pop-up content */
#pop-up-content-wrapper {
  display: flex;
  position: relative;
  flex: 1;
  min-height: auto;
  overflow-y: auto;
  flex-direction: column;
  background-color: #fff;
}

#pop-up-content {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.pop-up-content-flex {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
}

#pop-up-content-bottom-wrapper {
  display: block;
  height: 100%;
}

#pop-up-content-bottom-wrapper-flex {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
}

#pop-up-content-left {
  display: flex;
  flex: 3;
  flex-direction: column;
  padding: 20px 0;
}

#pop-up-content-left-title {
  text-align: left;
  font-size: 27px;
  letter-spacing: 0px;
  color: #202020;
  font-weight: 600;
}

#pop-up-content-left-dimesions {
  text-align: left;
  font-size: 15px;
  letter-spacing: 0px;
  color: #8B8B8B;
  margin-bottom: 10px;
}

#pop-up-content-left-description {
  margin-top: 20px;
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  color: #202020;
}

#pop-up-content-right {
  display: flex;
  flex: 2;
  flex-direction: column;
  padding: 20px 0;
}

#pop-up-content-price-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.pop-up-content-price-wrapper-2 {
  display: flex;
}

#pop-up-content-price-valute {
  text-align: left;
  font-size: 27px;
  color: #202020;
  font-weight: 600;
}

#pop-up-content-price-price {
  text-align: left;
  font-size: 27px;
  letter-spacing: 0px;
  color: #DC0000;
  font-weight: bold;
}

#pop-up-content-price-area {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  font-size: 17px;
  font-weight: 300;
  margin-top: 10px;
}

.pop-up-content-spec-price-wrapper {
  font-size: 20px;
  display: flex;
  flex-wrap: wrap;
}

img.tile-detail {
  width: 100%;
  align-self: center;
  margin-bottom: 20px;
}

.pop-up-content-bg-image {
  position: relative;
  margin: 0;
  width: 100%;
}

#pop-up-content-button {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  min-height: 55px;
  width: 100%;
  background: #DC0000 0% 0% no-repeat padding-box;
  font-size: 15px;
  color: #fff;
  align-items: center;
  text-align: center;
  position: sticky;
  bottom: 0;
  cursor: pointer;
}

/**
* Pattern controls
*/
#pattern-container {
  margin-left: 8px;
}

div#pattern-wrapper{
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-evenly;
  background-color: #fff;
  border-radius: 6px;
  overflow: hidden;
  height: 60px;
  width: 60px;
  border: 1px solid #BCBCBC;
}

#pattern-img-select {
  height: 100%;
  width: 60px;
  padding: 4px;
}

div#pattern-img-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

div#pattern-pop-up {
  display: none;
  z-index: 10;
}

div#pattern-pop-up.shown {
  /* display: block; */
  display: flex;
  /* background-color: #fff; */
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  /* overflow: auto; */
}

.pattern-pop-up-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  position: relative;
}

.pattern-pop-up-content-wrapper {
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
}

#pop-up-patterns {
  display: grid;
  grid-template-columns: auto auto auto;
  padding: 0 20px;
  grid-gap: 0px;
  justify-content: space-between;
}

.pattern-img-div {
  margin: 5px 0;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
}

div.pattern-img {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pattern-img svg {
  pointer-events: none;
}

#pattern-img-select svg {
  pointer-events: none;
}

@media screen and (max-width: 320px) and (orientation:portrait) {
  .pattern-img {
    width: 80px;
    height: 80px;
  }

  .pattern-img svg {
    pointer-events: none;
    width: 80px;
    height: 80px;
  }
}

.pattern-img-select svg {
  width: 100%;
  height: 100%;
}


.pattern-img.selected svg {
  padding: 0;
  outline: 3px solid cyan;
  -moz-outline-radius: 5px;
  animation: border-pulsate 1.5s infinite;
}

.pop-up-colors {
  /* padding: 10px 20px;
  display: flex;
  flex-direction: row; */

  padding: 10px 20px;
  display: grid;
  grid-template-columns: auto;
  grid-gap: 0;
  justify-content: flex-start;
  grid-auto-flow: column;
}

.color-div {
  width: 54px;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
}

.color-wrapper {
  height: 50px;
  width: 50px;
  background: #fff 0% 0% no-repeat padding-box;
  border: 1px solid #606060;
  padding: 2px;
  border-radius: 6px;
  align-self: center;
}

.color {
  height: 100%;
  border-radius: 6px;
  cursor: pointer;
}

.color-text {
  text-align: center;
}

.color-name {
  text-align: center;
  font-size: 8px;
  font-weight: 600;
  height: 24px;
}

.color.selected {
  outline: 3px solid cyan;
  -moz-outline-radius: 6px;
  animation: border-pulsate 1.5s infinite;
}

.pop-up-colors-palette {
  margin: 0 20px 20px 20px;
  padding: 9px 0 0 9px;
  display: block;
  border: 1px solid #DFDFDF;
  border-radius: 6px;
  min-height: fit-content;
}

.pop-up-colors-palette-flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.palette-wrapper {
  height: 37px;
  width: 37px;
  background: #fff 0% 0% no-repeat padding-box;
  border: 1px solid #c9c9c9;
  border-radius: 6px;
  margin-right: 9px;
  margin-bottom: 9px;
}

.palette {
  height: 100%;
  border-radius: 6px;
  cursor: pointer;
}

.palette.selected {
  outline: 3px solid cyan;
  -moz-outline-radius: 6px;
  animation: border-pulsate 1.5s infinite;
}
