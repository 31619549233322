.logout {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #E5171A;
  border-radius: 6px;
  background-color: #E5171A;
  color: #fff;
  min-height: 50px;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  outline: none;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.logout:hover {
  background-color: hsl(359, 82%, 40%);
}

@media only screen and (max-width: 320px) {
  .logout {
    min-height: 40px;
  }
}
